/**
 * @ngInject
 */
export default class navbarController {
  constructor($state, $http, ngNotify) {
    this.$state = $state;
    this.$http = $http;
    this.ngNotify = ngNotify;
  }

  $onInit() {
    if (window.location.href.indexOf("#contacto") != -1) {
      $("#exampleModalContact").modal("show");
    }
    this.Contact = {
      fullName: "",
      email: "",
      contactNumber: "",
      messageBody: "",
      text: "",
      subject: "",
      get html() {
        return `<h4>
                  <strong>${this.fullName}</strong> lo contacta<br>desde<strong></strong> entregando los siguientes datos:
                  <br>
                  <br>
                  <strong>Nombre:</strong> ${this.fullName}<br>
                  <strong>Numero de Contacto:</strong> ${this.contactNumber}<br><br>
                  <strong>Motivo:</strong> ${this.subject}<br><br>
                  <strong>Mensaje:</strong> ${this.messageBody}<br>
                </h4>`;
      },
    };
  }

  onSubmit() {
    let self = this;

    self
      .$http({
        method: "POST",
        url: "https://webservices.trust.lat/api/email/login",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: {
          user: "backendRocks!",
        },
      })
      .then(function (response) {
        console.log(response);
        return self.$http({
          method: "POST",
          url: "https://webservices.trust.lat/api/email/sendmail",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${response.data.token}`,
          },
          data: {
            email: self.Contact.email,
            subject: self.Contact.subject,
            html: self.Contact.html,
          },
        });
      })
      .then(() => {
        this.ngNotify.set(
          "Mensaje enviado correctamente. Lo contactaremos a la brevedad",
          {
            type: "info",
            position: "top",
            duration: "5000",
          }
        );
        $("#exampleModalContact").modal("hide");
      })
      .catch(() => {
        this.ngNotify.set(
          "Ocurrió un error, revise su conexión a internet e intente nuevamente",
          {
            type: "error",
            position: "top",
            duration: "5000",
          }
        );
      });
  }
}
