import es from "../locales/es.json";
import en from "../locales/en.json";

/* @ngInject */
export default function translateProvider($translateProvider) {
  $translateProvider.translations("es", es);
  $translateProvider.translations("en", en);

  $translateProvider.preferredLanguage('es');
  console.log(es)
}