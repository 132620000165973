/* @ngInject */
export default function uiRouterProvider($stateProvider, $urlRouterProvider, $locationProvider) {
  $urlRouterProvider.when('', '/');

  $urlRouterProvider.otherwise('/');

  $locationProvider.html5Mode(true);

  $stateProvider
    .state("landing", {
      url: "/",
      component: "appLanding",
    })
    .state("login", {
      url: "/login",
      component: "appLogin",
    })
    .state("about", {
      url: "/about",
      component: "appAbout",
    })
    .state("tos", {
      url: "/tos",
      component: "appTos",
    })
    .state("enrol", {
      url: "/enrol",
      component: "appEnrol",
    })
    .state("noti", {
      url: "/noti",
      component: "appNoti",
    })
    .state("prices", {
      url: "/prices",
      component: "appPrices",
    })
    .state("coverme", {
      url: "/coverme",
      component: "appCoverme",
    })
    .state("anotificador", {
      url: "/anotificador",
      component: "appAnotificador",
    })
    .state("ahmas", {
      url: "/ahmas",
      component: "appAhmas",
    })
    .state("work", {
      url: "/work",
      component: "appWork",
    })
    .state("desk", {
      url: "/desk",
      component: "appDesk",
    })
    .state("clientes", {
      url: "/clientes",
      component: "appClientes",
    })
    
}