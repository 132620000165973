import "../style/app.css";

import uiRouterProvider from "./configs/uiRouterProvider";
import translateProvider from "./configs/translateProvider";

import appRun from "./app.run";


import LandingView from "./views/landing/";

import NavbarComponent from "./components/navbar/";
import FooterComponent from "./components/footer/";

import SameAsDirective from "./directives/same-as/";

const moduleName = "app";

export default angular
  .module(moduleName, [
    "ui.router",
    "angular-inview",
    "ngNotify",
    'pascalprecht.translate'
  ])
  .config(uiRouterProvider)
  .config(translateProvider)
  .run(appRun)

  .component('app', {
    template: `<ui-view></ui-view>`
  })

  .component("appLanding", LandingView)

  .component("navbar", NavbarComponent)
  .component("appFooter", FooterComponent)

  .directive("sameAs", SameAsDirective)
