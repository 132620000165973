/* @ngInject */
export default class footerController {
  constructor($scope, $translate) {
    this.$scope = $scope;
    this.$translate = $translate;
  }

  $onInit() {
    const languages = ["es", "en"];
    window.selectLanguage(
      languages.indexOf(this.$translate.proposedLanguage())
    );
  }

  changeLocaleTo(key) {
    this.$translate.use(key);
  }
}