import template from "./template.html";
import controller from "./controller.js"

const bindings = {
  reverse: "<?",
}

export default {
  template,
  controller,
  bindings,
}