import template from "./template.html";
import controller from "./controller.js";

const bindings = {
  background: "<",
  fixedtop: "<",
}

export default {
  template,
  controller,
  bindings,
}

