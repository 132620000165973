"use strict";

export default () => {
  return {
    scope: {
      ngModel: "=",
    },
    link: function ($scope, $element, $attrs, $controller, $transcludeFn) {
      function compareAndValidate(firstValue, secondValue) {
        if (!firstValue || !secondValue) {
          $element[0].setCustomValidity("Completa este campo");
        } else if (firstValue !== secondValue) {
          $element[0].setCustomValidity("La contraseña no coincide");
        } else {
          $element[0].setCustomValidity("");
        }
      }

      $attrs.$observe("sameAs", function (value) {
        compareAndValidate(value, $scope.ngModel);
      })

      $scope.$watch(() => $scope.ngModel, function (value) {
        compareAndValidate(value, $attrs.sameAs);
      });
    }
  }
}